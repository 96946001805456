<template>
  <v-menu
    bottom
    left
    :close-on-content-click="false"
    offset-y
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-list-item
        class="py-0 justify-center"
        v-bind="attrs"
        v-on="on"
        @click="markAllAsSeen"
      >
        <v-badge
          color="orange"
          overlap
          dot
          :content="countUnseenMessage"
          :value="countUnseenMessage"
        >
          <v-icon color="blue accent-3">
            mdi-bell-outline
          </v-icon>
        </v-badge>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title v-if="notifications.length > 0">
        <span class="caption">
          Vous avez
          <b>{{ notifications.length }}</b> notifications
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list class="py-0">
          <v-divider></v-divider>
          <template v-for="(item, index) in notifications">
            <v-list-item :key="index" class="pl-4 pr-15">
              <v-list-item-content>
                <span v-html="item.message"></span>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index < notifications.length - 1"
              :key="'divider-' + index"
            ></v-divider>
          </template>
          <v-list-item v-if="notifications.length <= 0" class="pl-4 pr-15">
            <v-list-item-content>
              Vous n'avez aucune notification.
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item class="pt-0 pb-2" v-if="notifications.length > 0">
            <v-list-item-content>
              <v-btn color="primary" small text> tout voir</v-btn>
            </v-list-item-content>
          </v-list-item> -->
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$store.state.notifications || []
    },
    countUnseenMessage() {
      return (this.$store.state.notifications || []).filter(n => !n.seen).length
    },
  },
  data() {
    return {}
  },
  methods: {
    markAllAsSeen() {
      this.$store.commit('markAllNotificationsAsSeen')
    },
  },
}
</script>

<style lang="scss" scoped></style>
