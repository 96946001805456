<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    :style="styles"
    width="78"
    class="py-3 d-print-none"
  >
    <div class="d-flex project-nav" style="height:100%;">
      <div
        class="d-flex grow-shrink-0 flex-grow-0 flex-column drawer-nav"
        style="min-width:78px;"
      >
        <div class="d-flex flex-grow-1 flex-shrink-0 flex-column  drawer-nav ">
          <div class="text-center mt-2">
            <v-img
              class="d-inline-block"
              width="45"
              contain
              position="center center"
              src="@/assets/Logo-BNC.png"
            />
          </div>

          <v-list nav class="mt-4 px-4">
            <v-list-item
              class="py-0 justify-center"
              v-for="(link, i) in links"
              :key="i"
              exact
              :to="link.to"
            >
              <v-icon color="blue accent-3">{{ link.icon }}</v-icon>
            </v-list-item>
          </v-list>
        </div>

        <div class="d-flex flex-shrink-1" style="flex-direction:column;">
          <v-list nav class="px-4">
            <!-- <v-list-item class="py-0 justify-center">
              <v-icon color="blue accent-3">mdi-view-grid-outline</v-icon>
            </v-list-item>

            <notifications /> -->

            <v-list-item
              class="py-0 justify-center"
              :to="{ path: '/parametres' }"
            >
              <v-icon color="blue accent-3">mdi-cog-outline</v-icon>
            </v-list-item>
            <v-list-item
              class="py-0 justify-center"
              @click="handleReturnToAdmin"
              v-if="$store.getters.isPreviewing"
            >
              <v-icon color="blue accent-3">mdi-refresh</v-icon>
            </v-list-item>

            <v-list-item class="py-0 justify-center" @click="handleLogout">
              <v-icon color="blue accent-3">mdi-logout</v-icon>
            </v-list-item>
            <v-list-item class="py-0 justify-center" :to="{ path: '/profile' }">
              <v-avatar size="32px">
                <v-icon color="blue accent-3">
                  mdi-account-circle
                </v-icon>
              </v-avatar>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import DrawerLinks from '@/utils/drawer-links'
import { logout } from '@/utils/auth'
import Notifications from '@/components/base/Notifications'
import { mapMutations, mapState } from 'vuex'
const Config = require('@/utils/theme-config')
export default {
  components: {
    // Notifications,
    // UploadFileBtn
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    // links: [],
    app: {
      logo: Config.app.logo,
    },
  }),
  computed: {
    links() {
      return DrawerLinks[
        this.$store.getters.isUserAdmin && !this.$store.getters.isPreviewing
          ? 'admin'
          : 'default'
      ]
    },
    styles() {
      return {
        background: '#dae1f1',
        boxShadow: '0 0 2rem 0 rgba(136,152,170,.15)',
      }
    },
    ...mapState('app', ['image', 'color']),
    inputValue: {
      get() {
        return this.$store.state.app.drawer
      },
      set(val) {
        this.setDrawer(val)
      },
    },
  },
  // created () {
  //   this.links =
  //     DrawerLinks[this.$store.state.user.admin ? 'admin' : 'default']
  // },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    handleReturnToAdmin() {
      this.$store.commit('setPreview', null)
    },
    handleLogout() {
      logout()
    },
  },
}
</script>
<style lang="scss">
#app-drawer {
  width: 111.56px;
  height: 1080px;
  padding: 0px !important;
}

#app-drawer .v-list .v-list-item--active {
color:#2d62ed;
}

@media screen and (max-height: 600px) {
  .project-nav {
    overflow-y: hidden;
  }

  .drawer-nav {
    overflow-y: auto;
  }
}
</style>
